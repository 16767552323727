.dashboard-card{
    border-radius: 4px;
    border: none;
    box-shadow: 0px 2px 2px 0px #bfbfbf;
    background: white;
}
.tag-content{
    padding: 15px 0px;
    text-align: center; 
    /* width: 197px; */
    background: white;
    margin-right: 1%;
}

.dashboard-card p {
   font-size: 14px;
   font-weight: 500;
}

.rent-chart {
    padding: 10px;
}

.building-img{
    width: 100%;
    border-radius: 4px;
}
img.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

button.see-all-btn {
    border: none;
    border-radius: 5px;
    background: #BBC9F9;
}
.spiner{
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

.dashboard .MuiPaper-rounded {
    border-radius: 4px !important;
}

.dashboard h6.MuiTypography-root.MuiTypography-h6 {
    font-size: 15px;
}