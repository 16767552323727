.modal-container{
    width: 400px;
}
.modal-container .modal-conatiner-header{
    display: flex;
    justify-content: space-between;
}
.modal-container .modal-container-header-title{
    font-size: 24px;
}
.modal-container{
    width: 400px;
}
.modal-container .float-left{
    float: right;
}