/* saas css start  */

#sass-table-container .business-pro
{
    background-color: #035E7B;
    color: white;
    padding: 10px 10px;
    border-radius: 10px;
}

#sass-table-container .business-pro h1{
    font-size: 20px;
}
#sass-table-container .business-pro p{
    font-size: 12px;
}

#sass-table-container .saas-card-body{
    background-color: #d9d9d91e;
    padding: 10px 10px;
    border-radius: 6px;
    margin-top: 10px;
}