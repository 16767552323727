/* builling setup css  */
ul {
    list-style: none;
}

.bulling_tab .builling_link {
    background-color: #D2D2D2;
    border: none;
    border-radius: 4px;
    color: #fff;
    border-radius: 6px;
    margin-left: -1px;
    padding: 10px 20px;
}


.bulling_tab .nav-link.builling_link.active {
    background-color: #035E7B;
    z-index: 1;
}

.billing_list {
    position: relative;
    margin-right: 3px;
}

.builling_item_one {
    border: 21px solid #fff;
    position: absolute;
    right: -40px;
    top: -2px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 6px;
    z-index: 1;
}

.builling_item {
    border: 20px solid #D2D2D2;
    position: absolute;
    right: -36px;
    top: -1px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 6px;
    z-index: 2;
}

.bulling_tab .nav-link.builling_link.active+.builling_item {
    border: 20px solid #035E7B;
    position: absolute;
    right: -36px;
    top: -1px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 6px;
    z-index: 2;
}

.builling-card-container {
    background-color: #ffff;
    border: 2px solid #f3f3f3;
    padding: 14px 16px;
    border-radius: 10px;
    margin-top: 10px;
    height:320px;
    max-height:100%;
    position: relative;
}

.builling-header-item {
    background-color: #ffff;
    border: 2px solid #f3f3f3;
    padding: 8px 16px;
    color: black;
    border-radius: 6px;
    font-weight: 500;
    margin-right: 10px;
    font-family: "Roboto", sans-serif;
}
.builling-header-item.active {
    background-color: #F2AB48;
    color: #fff;
}
.builling-header-item.active:hover{
    color: #fff;
}
.builling-card-header-container h1 {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

.builling-card-header-container h2,
.builling-card-header-container p {
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.builling-card-header-container h2 span,
.builling-card-header-container p span {
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.builling-card-list ul {
    list-style-type: none;
}

.builling-center-content .heading {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
}

.builling-center-content .heading_one {
    font-size: 30px;
    padding: 10px 0;
    font-family: "Roboto", sans-serif;
}
.billing-setup-right-content-container {
    background-color: #f1f1f1;
    border-radius: 10px;
    margin-top: 14px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 48px;
    width: 32%;

}

.billing-setup-right-content-container ul li {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.billing-setup-right-content-container ul li:last-child {
    font-size: 24px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
}

.billing-setup-rign-button {
    background-color: #035E7B;
    border: 2px solid transparent;
    padding: 8px 16px;
    color: #fff;
    border-radius: 6px;
    font-weight: 500;
    margin-top: 12px;
    font-family: "Roboto", sans-serif;
}

.billing-card-filter {
    background-color: #f1f1f1;
    padding: 10px 20px;
    margin-top: -6%;
    height: 100vh;
}

.builling-filter-button-item {
    background-color: #ffff;
    border: 2px solid #f3f3f3;
    padding: 8px 10px;
    color: black;
    border-radius: 6px;
    font-weight: 500;
    margin-right: 2px;
    font-family: "Roboto", sans-serif;
}

.show {
    transition: all linear 0.4s;
    /* overflow-x: hidden;
    overflow-y: clip; */
}

.hide {
    transition: all linear 0.4s;
}
.displayNone{
    display: none;
}

@media print {
    /* .builling-card-header-container h1 {
        font-size: 14px;
        font-family: "Roboto", sans-serif;
    }
    
    .builling-card-header-container h2,
    .builling-card-header-container p {
        font-size: 14px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
    }
    
    .builling-card-header-container h2 span,
    .builling-card-header-container p span {
        font-size: 14px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
    }
    .builling-center-content .heading {
        font-size: 12px;
        font-family: "Roboto", sans-serif;
    }
    
    .builling-center-content .heading_one {
        font-size: 30px;
        padding: 10px 0;
        font-family: "Roboto", sans-serif;
    }

    .billing-setup-right-content-container ul li {
        font-size: 16px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
    }
    
    .billing-setup-right-content-container ul li:last-child {
        font-size: 24px;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
    } */

    /* start  */
    .content-to-print {
        display: block;
        /* Show this content during printing */
    }
    .displayNone{
        display: block;
    }
    .content-not-to-print {
        display: none;
        /* Hide this content during printing */
    }

    .header-right-button {
        background-color: #959595 !important;
        width: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        margin: 0;
        padding: 4px 0;
        print-color-adjust: exact;
    }
    .logo-statement-two {
        background-color: #035E7B;
        width: 100%;
        text-align: center;
        color: white;
        margin-top: 4px;
        print-color-adjust: exact;
    }
    .logo-statement-one {
        background-color: #035E7B;
        width: 200px;
        text-align: center;
        padding: 0.5px 0;
        text-transform: capitalize;
        print-color-adjust: exact;
    }
    .header-left-button p {
        background-color: #959595 !important;
        width: 90%;
        text-align: center;
        color: white;
        font-weight: bold;
        margin: 0;
        padding: 2px 0;
        print-color-adjust: exact;
    }
    .footer-button {
        background-color: #035E7B;
        padding: 6px 16px;
        color: #fff;
        border: none;
        print-color-adjust: exact;
    }

    .service-group-cell {
        background: #959595 !important;
        padding: 4px;
        text-align: center;
        color: white;
        print-color-adjust: exact;
    }
}