@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

.invoice-print {
    font-family: 'Kanit', sans-serif;
}

.invoice-pharmacy-details p {
    margin-bottom: 0;
    font-size: 14px;
}
.invoice-pharmacy-details h5 {
    margin-bottom: 0;
}

.invoice-date p {
    margin-bottom: 0;
    font-size: 14px;
}
.invoice-creator p{
    margin-bottom: 0;
    font-size: 14px;
}
.branding-section p {
    margin-bottom: 0;
    font-size: 12px;
}

.invoice-item-table table {
    width: 100%;
    text-align: center;
    table-layout: auto;
}
.custom-td {
    border: none !important;
}

.invoice-item-table table tr td {
    font-size: 13px;
    padding: 2px 5px;
    border : 1px solid #bfc1c3;
}

.bill-entry-table table {
    width: 100%;
    text-align: center;
    table-layout: auto;
}
.bill-entry-table table tr td {
    font-size: 13px;
    padding: 0 5px;
    border : 1px solid #bfc1c3;
}

.invoice-border-dashed {
    border-bottom: 1px dashed #363636;
}

.invoice-border-dashed-top {
    border-top: 1px dashed #363636;
}

.sales-invoice {
    display: none;
}

@media print {
    .sales-invoice {
        display: block;
    }
}

/* All invoice container  */
.all-invoice-container table {
    width: 100%;
}
.all-invoice-container .all-invoice-thead  {
    border-bottom: 2px solid #ced4da;
    background-color: #E9F3DF;
    border-radius: 15px;
}
.all-invoice-container tr  {
    cursor:pointer
}
/* All invoice container  */
/* spinner  */
.divLoader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }