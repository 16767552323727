.left_panel{
    border-right: 1px solid rgb(204, 204, 204);
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
}
.flat {
    border: 1px solid rgb(204, 204, 204);
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    cursor: pointer;
}
.flat:hover{
    background: #E74C3C;
    color: white;
}
.active.flat {
    background: #E74C3C;
    color: white;
}
.owner_info {
    border: 1px solid rgb(204, 204, 204);
    padding: 5px;
    border-radius: 3px;
    /* width: fit-content; */
}
.billing_right_content_height{
    height: 330px;
    overflow: hidden;
    overflow-y: auto;
}
.billing_table_height{
    height: 136px;
    overflow: hidden;
    overflow-y: auto;
}
.billing_table_height thead{
    background: #ebebeb;
}
.billing_qty input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}
.bill_entry_input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
 font-size: 11px !important;
}
.bill_entry_input{
    font-size: 11px !important;

}

.payment-container {
    border: 1px solid #80808066;
    padding: 0 10px;
}

.vaital-setup-btn {
    background: #69b128;
    color: #fff;
    padding: 3px 20px;
    border: none;
    border-radius: 5px;
}

.money-amount-box {
    border-radius: 5px;
    box-shadow: 0 4.62654px 10.7953px rgba(0,0,0,.25);
    padding: 3px 20px;
    
}
.billing-statement-print {
    display: none;
}
.payment_slip {
    display: none;
}
.signature {
    display: none !important;
}

.statment-heading {
    display: none;
  }


.custom_date_picker_styles {
    width: 150px; 
    padding: 6px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    font-size: 16px; 
}
/* .react-datepicker-ignore-onclickoutside {
    padding: 9px;
    border: none;
    border-radius: 7px;
} */

.button-active{
    border: 1px solid #035E7B;
}

.billing_Statment_header{
  background: gray;
  color: white;
}


@media print{
  .bill-sum-print{
    padding: 20px;
  }
    .payment_slip {
        display: block;
    }
    .history-body{
        margin: 40px;
    }
    .billing-statement-print{
        display: block;
        margin: 20px;
    }
    .print_icon{
        display: none;
    }
    .signature {
        display: block !important;
    }
    .statment-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    /* @page {
        size: landscape; 
    } */
    
    .billing_Statment_header{
        background: gray;
        color: white;
        print-color-adjust: exact;
      }

    
}