.building-tabs{
    border-bottom: none !important;
}

.building-tabs .nav-item .nav-link {
    border: none !important;
    color: white !important; 
    background: #9c9c9c !important;
    border-radius: 4px;
    margin-right: 3px;
    padding: 5px 15px;
}

.building-tabs .nav-item .nav-link.active {
    border: none !important;
    color: white !important; 
    background: #9b1111 !important;
    border-radius: 4px;
    font-weight: 500;
}

svg.menu-icon{
    font-size: 28px;
    color: red;
}