.billing_table th {
    font-weight: 500 !important;
    text-align: center !important;
    border: 0px solid #cac7d3!important; 
    font-size: 13px !important;
}
.billing_table .MuiTableCell-root {
    padding: 4px !important;
}
.billing_table table{
    border: 1px solid #cac7d3!important;
} 
/* .billing_table{
    background: #fff;
} */
.billing_table .MuiPaper-elevation2 {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0),0px 2px 2px 0px rgba(0,0,0,0.0),0px 1px 5px 0px rgba(0,0,0,0.0);
}
.billing_table .MuiToolbar-regular{
    min-height: 8px!important;
}
.billing_table tfoot.MuiTableFooter-root tr td{
    padding: 0!important;
} 

.billing_table .search{
    padding: 4px 0px; display: block;
    width: 100%;
    padding: 2px 4px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.billing_table .MuiSvgIcon-root {
    fill: currentColor;
    width: 14px;
    height: 14px;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}


/* sass billing  */

.sass_billing_table th {
    font-weight: 500 !important;
    text-align: center !important;
    border: 0px solid #cac7d3!important; 
    font-size: 13px !important;
}
.sass_billing_table thead {
    border-top: 0px solid #cac7d3;
    border-bottom: 1px solid #cac7d3;
}
.sass_billing_table tfoot {
    border-top: 0px solid #cac7d3;
    border-bottom: 0px solid #cac7d3;
}
.sass_billing_table .MuiTableCell-root {
    padding: 4px !important;
}
.sass_billing_table table{
    border: 0px solid #cac7d3!important;
}
.sass_billing_table .MuiPaper-elevation2 {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0),0px 2px 2px 0px rgba(0,0,0,0.0),0px 1px 5px 0px rgba(0,0,0,0.0);
}
.sass_billing_table .MuiToolbar-regular{
    min-height: 8px!important;
}
.sass_billing_table tfoot.MuiTableFooter-root tr td{
    padding: 0!important;
} 

.sass_billing_table .search{
    padding: 4px 0px; display: block;
    width: 100%;
    padding: 2px 4px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sass_billing_table .MuiTableCell-root:last-child {
    border-bottom: 0px solid
    rgba(224, 224, 224, 1);
} 
.sass_billing_table .MuiSvgIcon-root {
    fill: currentColor;
    width: 14px;
    height: 14px;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.sass_billing_table .MuiTableCell-root.MuiTableCell-head .PrivateSwitchBase-root-7 {
padding: 0;
margin-left: -7px;
}
.fee-setup-table .MuiPaper-elevation2 {
    box-shadow: none;
}
.fee-setup-table .MuiToolbar-regular {
    min-height: 0px !important; 
}
/* .sass_billing_table */


/* .bill_entry_table .MuiTable-root {
    position: relative;
  }
  .bill_entry_table .background{
    position: fixed;
    background-color: red;
    left: 20%;
    top:100px;
    width: 400px;
  } */

/* columns[0].cellStyle = {
    backgroundColor: '#007eff',
    color: '#FFF',
    position: 'sticky',
    left: 0
} */